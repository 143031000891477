import { useRef, Suspense, useLayoutEffect, ReactNode } from 'react'
import Modal from '@ui-components-3/ui/lib/components/Modal'
import Modals from 'singletons/Modals'
import V2ModalsMap, { V2ModalMapType } from '../v2Modals'
import classNames from '@ui-components-3/ui/lib/utils/classNames'

const DefaultContainer = ({ children }: Record<string, any> & { children: ReactNode }) => <>{children}</>

type ModalWrapperV2 = {
  type: V2ModalMapType
  id: number
  options: object
}

const ModalWrapperV2 = ({ type, id, options }) => {
  const overrideStrictModeRef = useRef(false)
  const { Content, Footer, label, size, Container = DefaultContainer, footerVariant, className } = V2ModalsMap[type]
  useLayoutEffect(() => {
    document.body.style.overflow = 'hidden'
    return () => {
      if (!Modals.some((m) => !!m)) {
        document.body.style.overflow = 'unset'
      }
    }
  }, [])
  return (
    <Suspense fallback="Loading modal">
      <Container {...options}>
        <Modal
          size={size ?? 'm'}
          ref={(m) => {
            if (m && !overrideStrictModeRef.current) {
              overrideStrictModeRef.current = true
              m.open()
            }
          }}
          onClose={() => {
            Modals.close(id)
          }}
          onCustomClose={() => {
            options?.onCustomClose?.()
            Modals.close(id)
          }}
          label={typeof label === 'function' ? label(options) : label}
          footer={Footer ? (modalProps) => <Footer {...options} {...modalProps} /> : undefined}
          renderPropsOptions={options}
          className={classNames('backdrop:backdrop-blur', className)}
          footerVariant={footerVariant}
        >
          {(modalProps) => <Content {...options} {...modalProps} />}
        </Modal>
      </Container>
    </Suspense>
  )
}

export default ModalWrapperV2
