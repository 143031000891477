import { lazy } from 'react'
import format from 'date-fns/format'
import { isSuccessVisit } from 'constants/visit-types'

const V2ModalsMap = {
  dayVisitsV2: {
    label: ({ dateString }) => format(new Date(dateString), 'EEEE, MMMM do'),
    Content: lazy(() => import('../v2Modals/DayVIsits')),
  },
  rescheduleVisit: {
    label: 'Reschedule Visit',
    Content: lazy(() => import('../v2Modals/RescheduleVisit')),
  },
  briefSchedulingV2: {
    label: ({ visit }) => `Scheduled ${isSuccessVisit(visit?.visitType) ? 'Session' : 'Visit'}`,
    Content: lazy(() => import('../v2Modals/BriefScheduling/Content')),
    Footer: lazy(() => import('../v2Modals/BriefScheduling/Footer')),
  },
  timeSlotSave: {
    label: 'Time Slots Saved',
    Content: lazy(() => import('../v2Modals/TimeSlotSave/Content')),
    Footer: lazy(() => import('../v2Modals/TimeSlotSave/Footer')),
  },
  unsavedTimeSlots: {
    label: 'Unsaved Changes',
    Content: lazy(() => import('../v2Modals/UnsavedTimeSlots/Content')),
    Footer: lazy(() => import('../v2Modals/UnsavedTimeSlots/Footer')),
  },
  memberMedication: {
    label: ({ medicationId }) => `${medicationId ? 'Edit' : 'Add'} Medication`,
    Content: lazy(() => import('../v2Modals/MemberMedication/Content')),
    Footer: lazy(() => import('../v2Modals/MemberMedication/Footer')),
    Container: lazy(() => import('../v2Modals/MemberMedication/Container')),
    footerVariant: 'right',
  },
  addPrescription: {
    label: 'Add Prescription',
    Content: lazy(() => import('../v2Modals/AddPrescription/Content')),
    Footer: lazy(() => import('../v2Modals/AddPrescription/Footer')),
    Container: lazy(() => import('../v2Modals/AddPrescription/Container')),
    footerVariant: 'right',
  },
  medicationInteractionAlert: {
    label: 'Medication Interaction Alert',
    Content: lazy(() => import('../v2Modals/MedicationInteractionAlert/Content')),
    Footer: lazy(() => import('../v2Modals/MedicationInteractionAlert/Footer')),
    Container: lazy(() => import('../v2Modals/MedicationInteractionAlert/Container')),
    size: 'l',
    footerVariant: 'right',
  },
  pdfViewer: {
    Content: lazy(() => import('../v2Modals/PDFViewer/Content')),
    label: 'Policy',
    size: 'custom',
    className: 'w-[1000px] max-sm:max-h-[95dvh] [&>_:nth-child(2)]:!overflow-auto !overflow-hidden',
    footerVariant: 'right',
  },
} as const

export type V2ModalMapType = keyof typeof V2ModalsMap

export default V2ModalsMap
