import { MemberConsentsType } from 'types/PrivateData/Member'
import { InferType, object, string } from 'yup'
import queryString from 'qs'
export { provider as baseURL } from 'constants/apiBaseUrls'

const Schema = object({
  filter: object({
    member_id: string().required(),
  }),
})

export type Request = InferType<typeof Schema>

export const url = ({ filter }: Request) => `/v1/consents?${queryString.stringify({ filter })}`
export type SuccessfulResponse = MemberConsentsType[]
