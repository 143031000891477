import { MedicationInteractionsData } from 'types/Medications'
import { object, string, number, InferType } from 'yup'

export { provider as baseURL } from 'constants/apiBaseUrls'

export const Schema = object({
  memberId: string().required(),
  memberExternalId: string().required(),
  providerExternalId: string().notRequired().nullable(),
  visitId: string().required(),
  dispensableDrugId: number().required(),
})

export type Request = InferType<typeof Schema>

export type SuccessfulResponse = MedicationInteractionsData

export const url = () => '/v1/interactions'

export const getBody = (request: Request) => request
