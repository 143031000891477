import { runInAction, observable } from 'mobx'
import throttle from 'lodash/throttle'
import makeQuery from 'api/makeQuery'
import User from 'singletons/User'
import PrivateData from './PrivateData'
import PastVisitsCollection from 'models/Provider/PastVisitsCollection'
import Rxs from 'models/Provider/visits/PendingVisit/Rxs'

class MemberExtendedData {
  byMemberId = new Map()
  fetchQueue = new Set()
  memberVisitTypeFilter = ''

  processQueue = throttle(async () => {
    if (!this.fetchQueue.size) {
      return
    }

    const memberIds = Array.from(this.fetchQueue)

    for (const memberId of memberIds) {
      await this.refetch(memberId)
      this.fetchQueue.delete(memberId)
    }

    if (this.fetchQueue.size) {
      this.processQueue()
    }
  }, 500)

  async refetch(memberId) {
    const [privateData, historicalRxs] = await Promise.all([
      makeQuery('getMember', {
        providerId: User.providerId,
        memberId,
      }),
      makeQuery('getMemberPrescriptions', {
        memberId,
      }),
    ])

    runInAction(() => {
      const v = this.byMemberId.get(memberId)
      const vval = v.get()
      if (vval) {
        vval.visits.list.forEach((v) => v.destroy())
        vval.historicalRxs?.list?.forEach((rx) => rx.destroy())
        vval.privateData.destroy()
      }
      v.set({
        visits: new PastVisitsCollection({ memberId, memberVisitTypeFilter: this.memberVisitTypeFilter }),
        privateData: new PrivateData(privateData),
        historicalRxs: new Rxs(null, historicalRxs),
      })
    })
  }

  get(memberId, memberVisitTypeFilter) {
    let res = this.byMemberId.get(memberId)

    if (!res || this.memberVisitTypeFilter !== memberVisitTypeFilter) {
      this.memberVisitTypeFilter = memberVisitTypeFilter
      res = observable.box()
      this.byMemberId.set(memberId, res)
      this.fetchQueue.add(memberId)
      this.processQueue()
    }

    return res.get()
  }

  clear() {
    this.byMemberId.clear()
    this.fetchQueue.clear()
    this.processQueue.cancel()
  }
}

export default new MemberExtendedData()
