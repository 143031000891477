import { InferType, number, object, string } from 'yup'
export { provider as baseURL } from 'constants/apiBaseUrls'
import queryString from 'qs'
import { PaginatedResponse } from 'types/api'
import { ProviderMember } from 'types/ProviderMember'

export const Schema = object({
  providerId: string().required(),
  page: object({
    skip: number(),
    limit: number(),
  }),
})

export type Request = InferType<typeof Schema>

export type SuccessfulResponse = PaginatedResponse<ProviderMember>

export const url = ({ providerId, page }) =>
  `/v1/providers/${providerId}/members?${queryString.stringify({ page }, { indices: false })}`
